<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form
          label-width="50px"
          class="topS"
          :model="seekData"
          ref="seekData"
          :rules="rules"
        >
          <el-col :span="3">
            <el-form-item label="品牌" prop="brandId">
              <el-select
                v-model="seekData.brandId"
                @change="selectOnes"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in brandSelectDatas"
                  :key="index"
                  :label="item.brandName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="门店" prop="hotelId">
              <el-select
                v-model="seekData.hotelId"
                @change="selectHotel"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in hotelSelectDatas"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="建筑">
              <el-select
                v-model="seekData.buildingId"
                @change="selectBuilding"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in buildingSelectData"
                  :key="index"
                  :label="item.buildingName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item label="楼层">
              <el-select
                v-model="seekData.floorId"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in floorSelectData"
                  :key="index"
                  :label="item.floorName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="房间号" label-width="60px">
              <el-input
                placeholder="请输入内容"
                v-model.trim="seekData.roomNumber"
                clearable
                class="my-input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="版本">
              <el-input
                placeholder="请输入内容"
                v-model.trim="seekData.version"
                clearable
                class="my-input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="UUID">
              <el-input
                placeholder="请输入内容"
                v-model.trim="seekData.uuid"
                clearable
                class="my-input"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="3" class="reset-button">
            <el-button type="primary" @click="handleSearch('seekData')"
              >查询</el-button
            >
            <el-button @click="reset">重置</el-button>
          </el-col>
        </el-form>
      </el-card>
    </el-row>

    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            :data="tabList"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
          >
            <el-table-column
              prop="hotelName"
              label="酒店名称"
              width="280"
            ></el-table-column>
            <el-table-column
              prop="buildingName"
              label="建筑名称"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="floorName"
              label="楼层"
              width="80"
            ></el-table-column>
            <el-table-column
              prop="roomNumber"
              label="房间号"
              width="80"
            ></el-table-column>
            <el-table-column prop="ip" label="IP" width="120"></el-table-column>
            <el-table-column prop="mac" label="MAC"></el-table-column>
            <el-table-column prop="version" label="版本"></el-table-column>
            <el-table-column
              prop="uuid"
              label="UUID"
              width="280"
            ></el-table-column>
            <el-table-column prop="gateWay" label="网关"></el-table-column>
            <el-table-column prop="dns" label="DNS"></el-table-column>
            <el-table-column prop="lastComeTime" label="最后上线时间">
              <!-- <template slot-scope="scope">
           <div>
            {{ labelShowDate(scope.row.lastComeTime)}}
           </div>
         </template> -->
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :current-page="pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>
  </div>
</template>
<script>
import {
  getRcuInfoList,
  getHotelSelect,
  getBrandSelect,
  getFloorSelect,
  getBuildingSelect,
} from "@/api";
import { showDayTimeVal } from "@/api/common.js";

export default {
  data() {
    return {
      loading: false,
      // 建筑列表数据
      tabList: null,
      // 查询的数据
      total: null,
      seekData: {
        hotelId: null,
        brandId: null,
        buildingId: null,
        floorId: null,
        roomNumber: null,
        uuid: null,
        version: null,
      },
      pageSize: 10,
      pageNum: 1,
      // 下拉框的数据
      brandSelectDatas: null,
      hotelSelectDatas: null,
      buildingSelectData: null,
      floorSelectData: null,
      query: {},
      height: null,
      rules: {
        brandId: [{ required: true, message: "请选择", trigger: "change" }],
        hotelId: [{ required: true, message: "请选择", trigger: "change" }],
      },
    };
  },

  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 235;
  },
  created() {
    this.initialize();
    // this.initForm();
    // this.getList();
  },
  methods: {
    labelShowDate(val) {
      return showDayTimeVal(val);
    },
    initForm() {
      let user = JSON.parse(sessionStorage.getItem("user"));
      console.log(user);
      this.seekData.brandId = user.brandId;
      if (this.seekData.brandId) {
        this.selectOnes(this.seekData.brandId);
      }
      this.seekData.hotelId = user.storeId;
      if (this.seekData.hotelId) {
        this.selectHotel(this.seekData.hotelId);
      }
    },
    // 初始化表格数据
    getList() {
      this.loading = true;
      getRcuInfoList(this.pageNum, this.pageSize, this.query)
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.tabList = res.data.data.rows;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    initialize() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectDatas = res.data.data;
          this.seekData.brandId = this.brandSelectDatas[0].id;
          this.selectOnes(this.seekData.brandId, "search");
        }
      });
    },

    // 选择框
    selectOnes(id, type) {
      ["hotelId", "buildingId", "floorId"].forEach(
        (key) => (this.seekData[key] = null)
      );
      this.initHotel();
      if (!id) return;
      getHotelSelect(id).then((res) => {
        // console.log(res)
        if (res.data.data.length > 0) {
          this.hotelSelectDatas = res.data.data;
          this.seekData.hotelId = this.hotelSelectDatas[0].id;
          this.selectHotel(this.seekData.hotelId);
          if (type) this.handleSearch("seekData");
        } else {
          this.$message({
            message: "该品牌下没有门店数据",
            type: "warning",
          });
        }
      });
    },

    selectHotel(id) {
      this.seekData.buildingId = null;
      this.seekData.floorId = null;
      this.buildingSelectData = null;
      this.floorSelectData = null;
      if (!id) return;
      getBuildingSelect(id).then((res) => {
        this.buildingSelectData = res.data.data;
      });
    },
    // 获取楼层下拉框
    selectBuilding(id) {
      this.seekData.floorId = null;
      this.floorSelectData = null;
      if (!id) return;
      getFloorSelect(id).then((res) => {
        this.floorSelectData = res.data.data;
      });
    },

    handleSearch(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.pageNum = 1;
          this.query = Object.assign(this.seekData);
          this.getList();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 重置按钮
    reset() {
      [
        // "hotelId",
        // "brandId",
        "buildingId",
        "floorId",
        "roomNumber",
        "uuid",
      ].forEach((key) => (this.seekData[key] = null));

      this.floorSelectData = null;
      this.pageNum = 1;
      this.getList();
    },
    initHotel() {
      (this.buildingSelectData = null), (this.floorSelectData = null);
    },
    //分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    handleSizeChange(val, val3) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.my-box {
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
}
</style>
